import React, { useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';

const questions = [
    {
        id: 1,
        text: 'If you__ (burn) wood, ___you (get) ash. (General truth)',
        options: [
            {
                text: 'burn, gets',
                isCorrect: false,
                explanation: 'This is incorrect because it mixes verb tenses. In zero conditional, both the "if" clause and the result clause must be in the present simple. "Gets" is not appropriate here because the correct form in this context is the base form "get" without an "s."',
            },
            {
                text: 'burn, will get',
                isCorrect: false,
                explanation: 'This uses the future tense ("will get"), suggesting a specific or conditional event in the future, which is not the focus of zero conditional. Zero conditional describes what is always true, not what could happen in the future.',
            },
            {
                text: 'burn, get',
                isCorrect: true,
                explanation: '',
            },
        ],
        image: './assets/zero_burn.png'
    },
    {
        id: 2,
        text: 'When the sun sets, the sky:',
        options: [
            {
                text: 'darkens',
                isCorrect: true,
                explanation: '',
            },
            {
                text: 'darken',
                isCorrect: false,
                explanation: 'This is the base form of the verb, which does not agree with a third person singular subject in the present simple tense. It would be incorrect in this context',
            },
            {
                text: 'darkened',
                isCorrect: false,
                explanation: 'This is the past tense form of the verb "darken." It does not fit with the structure of the zero conditional, which uses the present simple tense.',
            },
        ],
        image: './assets/zero_sun.png'
    },
    {
        id: 3,
        text: 'In a zero conditional, the verb tense in both the condition and result clauses must be in the present simple',
        options: [
            {
                text: 'TRUE',
                isCorrect: true,
                explanation: 'The use of present simple in both clauses conveys that the outcome is predictable or a general truth. ',
            },
            {
                text: 'FALSE',
                isCorrect: false,
                explanation: 'If the condition and result clauses do not use the present simple tense, it no longer represents a zero conditional. Using other tenses, such as future or past, would indicate other conditional types (e.g., first conditional or second conditional), which describe events that might happen or things that are contrary to fact',
            },
        ],
    },
    {
        id: 4,
        text: 'Select the correct sentence that uses a zero conditional structure.',
        options: [
            {
                text: 'If the power goes out, the backup generator turns on',
                isCorrect: true,
                explanation: 'This sentence follows the zero conditional structure because both clauses are in the present simple tense. It describes a general truth: when a certain condition (power outage) occurs, there is always a specific result (the generator turns on)',
            },
            {
                text: 'If it will rain, we will stay indoors.',
                isCorrect: false,
                explanation: 'This structure uses the future tense ("will rain," "will stay"), indicating an event that might occur in the future. This construction aligns with the first conditional, used for hypothetical or future scenarios, rather than the zero conditional, which expresses general truths.',
            },
            {
                text: 'If you would touch it, you would get burned',
                isCorrect: false,
                explanation: 'This structure uses "would," indicating a hypothetical situation or possibility, often used in the second conditional to express events contrary to fact or unlikely scenarios. This does not meet the requirements for zero conditional, which relies on the present simple in both clauses',
            }
        ],
    },
    {
        id: 5,
        text: 'If you study hard, _____________________________________.',
        options: [
            {
                text: 'you get good grades.',
                isCorrect: true,
                explanation: 'This answer follows the zero conditional structure, using the present simple tense in both clauses. It indicates that studying hard typically leads to good grades, a general truth.',
            },
            {
                text: 'you got good grades.',
                isCorrect: false,
                explanation: 'This uses the past tense, which does not align with the zero conditional structure. This suggests that the action has already happened, which is not the intent of the zero conditional.',
            },
            {
                text: ' you getting good grades.',
                isCorrect: false,
                explanation: 'This is grammatically incorrect. The zero conditional requires the present simple tense, while "getting" is a gerund or present continuous, which does not fit this structure.',
            },
            {
                text: 'you will get good grades.',
                isCorrect: false,
                explanation: 'This uses the future tense ("will get"), indicating a future possibility or prediction, which is typical of the first conditional. This does not align with the zero conditionals use of present simple for general truths',
            }
        ],
    },
    {
        id: 6,
        text: ' I always wear a jacket _____________________________________. ',
        options: [
            {
                text: 'when it will be cold.',
                isCorrect: false,
                explanation: 'This uses the future tense ("will be"), which suggests a possible or predicted event, aligning more with the first conditional rather than the zero conditional. The zero conditional focuses on what generally happens under a given condition.',
            },
            {
                text: 'when it is cold.',
                isCorrect: true,
                explanation: 'This uses the present simple tense in the condition clause, which aligns with the zero conditional structure. It indicates that whenever it is cold, the action of wearing a jacket is the habitual response',
            },
            {
                text: 'when it was cold.',
                isCorrect: false,
                explanation: 'This uses the past tense ("was cold"), indicating a condition in the past. This does not match the zero conditional structure, as it should reflect present simple for general truths or habitual actions.',
            },
            {
                text: 'when it is being cold',
                isCorrect: false,
                explanation: 'This uses a present continuous tense, which is not suitable for zero conditional, as it indicates an ongoing action rather than a general truth or habit. The correct form for zero conditional uses the present simple tense.',
            }
        ],
    },
    {
        id: 7,
        text: 'When she works early, ___________________________________.',
        options: [
            {
                text: 'she woke up early',
                isCorrect: false,
                explanation: 'This uses the past tense ("woke"), indicating a past event, which is not appropriate for the zero conditional. Zero conditional focuses on general truths or habitual actions.',
            },
            {
                text: 'she will wake up early.',
                isCorrect: false,
                explanation: 'This uses the future tense ("will wake up"), which suggests a possible or expected outcome, aligning with the first conditional, not zero conditional.',
            },
            {
                text: 'she wake up early.',
                isCorrect: false,
                explanation: 'This is grammatically incorrect. The correct verb form for third person singular in the present simple is "wakes," not "wake."',
            },
            {
                text: 'she wakes up early.',
                isCorrect: true,
                explanation: 'This uses the present simple tense in the result clause, which is consistent with zero conditional structure. It indicates a habitual or predictable behavior when the condition of "working early" is met.',
            }
        ],
    },
    {
        id: 8,
        text: 'Which of the following sentences is NOT a zero conditional?',
        options: [
            {
                text: 'If it rains, the grass gets wet.',
                isCorrect: false,
                explanation: 'This is a zero conditional sentence because it uses the present simple tense in both the condition ("if it rains") and the result clause ("the grass gets wet"). It describes a general truth or a common occurrence.',
            },
            {
                text: 'When it is cold, water freezes.',
                isCorrect: false,
                explanation: 'This sentence also follows the zero conditional structure, with both clauses in the present simple tense. It describes a scientific fact or general truth.',
            },
            {
                text: 'If it will rain tomorrow, I will stay home.',
                isCorrect: true,
                explanation: 'This sentence does not follow the zero conditional structure because it uses the future tense ("will rain," "will stay"). This sentence indicates a hypothetical or potential future event, which aligns with the first conditional. First conditional is typically used to describe possible outcomes or predictions in the future based on a condition.',
            }
        ],
    },
    {
        id: 9,
        text: 'When the radio plays, _____________________________________.',
        options: [
            {
                text: 'it is hard to hear you speak.',
                isCorrect: true,
                explanation: 'This option is correct because it uses the present simple tense ("it is hard to hear") and maintains the structure of zero conditional. It reflects a general truth: when the radio is playing, hearing someone else speak is difficult',
            },
            {
                text: 'it was hard to hear you speak.',
                isCorrect: false,
                explanation: 'This uses the past tense ("was"), indicating a past event, which does not fit the zero conditional structure. Zero conditional should describe general truths or habitual outcomes.',
            },
            {
                text: 'it is hard to hear you spoke.',
                isCorrect: false,
                explanation: 'This has an incorrect verb form ("spoke"), which is past tense. For zero conditional, the correct verb tense in the result clause should be in the present simple, not past.',
            },
            {
                text: ' it is hard to hear you will speak.',
                isCorrect: false,
                explanation: 'This uses the future tense ("will speak"), suggesting a future event, which does not align with the zero conditional structure. Zero conditional does not focus on future possibilities or predictions.',
            }
        ],
    },
    {
        id: 10,
        text: 'If my cat_____ a dog, it runs away.',
        options: [
            {
                text: 'sees',
                isCorrect: true,
                explanation: 'This is the correct form for the verb in the third person singular present simple tense. It fits the zero conditional structure, indicating what typically happens when a specific condition is met. In this case, when the cat sees a dog, it habitually runs away.',
            },
            {
                text: 'seen',
                isCorrect: false,
                explanation: 'This is the past participle form of the verb "see." It does not fit the zero conditional structure, which uses present simple tense, not past participles.',
            },
            {
                text: 'saw',
                isCorrect: false,
                explanation: 'This is the past tense form of "see." It does not align with zero conditional, which should be in the present simple tense to describe general truths or habitual behavior.',
            },
        ],
    },
    {
        id: 11,
        text: 'If you eat fast food, ___________________________________.',
        options: [
            {
                text: 'you will gain weight',
                isCorrect: false,
                explanation: 'This uses the future tense ("will gain"), indicating a possible or hypothetical future outcome. This structure aligns more with the first conditional, used for predictions or hypothetical scenarios, rather than zero conditional',
            },
            {
                text: 'you gains weight.',
                isCorrect: false,
                explanation: 'This is grammatically incorrect. The correct form in the present simple for second person singular (you) is "gain," not "gains."',
            },
            {
                text: 'you gain weight.',
                isCorrect: true,
                explanation: 'This sentence uses the present simple tense ("gain") in the result clause, matching the structure of zero conditional. It describes a general truth that if you eat fast food, you tend to gain weight',
            },
            {
                text: 'you gained weight.',
                isCorrect: false,
                explanation: 'This uses the past tense ("gained"), suggesting an event that happened in the past. Zero conditional should be in the present simple to indicate general truths or habitual behavior',
            }
        ],
    },
];

function Lesson4(){
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleSelect = (questionId, optionIndex) => {
        setSelectedAnswers((prev) => ({
            ...prev,
            [questionId]: optionIndex,
        }));
    };

    const handleSubmit = () => {
        setSubmitted(true);
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center">
            <div className="w-full max-w-5xl bg-white p-8 rounded-xl shadow-xl mt-8 mb-8">
                <h1 className="text-3xl font-bold text-center text-red-500 mb-8">Multiple Choice Quiz</h1>
                <div className="space-y-6">
                    {questions.map((question, qIndex) => (
                        <motion.div
                            key={question.id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="bg-purple-100 p-6 rounded-lg shadow-lg transition-all"
                        >
                            <p className="text-xl text-gray-800">
                                {qIndex + 1}. {question.text}
                            </p>
                            {question.image && (
                                <img
                                    src={question.image}
                                    alt={`Image for question ${question.id}`}
                                    className="w-full h-40 object-contain rounded-lg my-4"
                                />
                            )}
                            <div className="flex flex-col gap-4 mt-4">
                                {question.options.map((option, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-center p-2 rounded-lg ${
                                            submitted
                                                ? selectedAnswers[question.id] === index
                                                    ? option.isCorrect
                                                        ? 'bg-green-100'
                                                        : 'bg-red-100'
                                                    : ''
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            name={`question-${question.id}`}
                                            value={index}
                                            checked={selectedAnswers[question.id] === index}
                                            onChange={() => handleSelect(question.id, index)}
                                            className="mr-2"
                                        />
                                        <label className="flex-grow text-gray-700">{option.text}</label>
                                    </div>
                                ))}
                            </div>
                            {submitted && (
                                <div className="mt-4">
                                    {question.options.map((option, index) => (
                                        <p key={index} className="text-sm">
                                            {option.isCorrect ? (
                                                <span className="text-green-600 flex items-center">
                                                    <FaCheck className="mr-2" /> {option.text}: {option.explanation}
                                                </span>
                                            ) : (
                                                <span className="text-red-600 flex items-center">
                                                    <FaTimes className="mr-2" /> {option.text}: {option.explanation}
                                                </span>
                                            )}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </motion.div>
                    ))}
                </div>
                {!submitted && (
                    <div className="text-center mt-8">
                        <button
                            onClick={handleSubmit}
                            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                        >
                            Submit Answers
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Lesson4;