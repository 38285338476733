import React, { useState } from "react";
import { Link } from "react-router-dom";

function Home(){
    const [answersVisible, setAnswersVisible] = useState({
        question1: false,
        question2: false,
        question3: false,
        question4: false,
    });

    const toggleAnswer = (questionId) => {
        setAnswersVisible((prevState) => ({
            ...prevState,
            [questionId]: !prevState[questionId],
        }));
    };
    return(
        <div>
            <section className="relative"> 
                <div className="container mx-auto px-6 py-12"> 
                    <div className="flex flex-col lg:flex-row items-center"> 
                    <div className="lg:w-1/2 text-center lg:text-left"> 
                        <h1 className="text-4xl font-bold"> 
                        Enjoy learning English with our discovery learning approach
                        </h1>
                        <p className="mt-6 text-gray-700"> 
                        Discovery learning through our site allows students to explore concepts on their own. Our site is a self-learning site where students can find and engage with information on their own. Our site helps not only students, but also teachers to save time with ready-made assignments. 
                        </p>
                        <Link to={`/lessons`}
                        className="inline-block mt-8 bg-purple-500 text-white font-bold border border-purple-500 px-6 py-3 rounded-lg hover:bg-purple-700 hover:text-white transition" 
                        >
                        Start Learning English
                        </Link>
                    </div>
                    <div className="lg:w-1/2 mt-10 lg:mt-0"> 
                        <img
                        className="w-full object-contain" 
                        src="./assets/img1.png"
                        width="603"
                        height="396"
                        alt="Banner"
                        />
                    </div>
                    </div>
                </div>
                
            </section>
            
            <section className="relative py-12 ">
                <div className="container mx-auto px-6">
                    <div className="flex flex-col lg:flex-row justify-between items-center lg:text-left">
                        <div className="lg:w-1/2">
                            <h1 className="text-4xl font-bold text-gray-800 mb-4 lg:text-5xl lg:mb-8">What Is Discovery Learning?</h1>
                            <p className="text-lg text-gray-700 lg:text-xl">
                                Discovery learning is an educational approach where students are encouraged to discover facts and relationships for themselves, as opposed to being explicitly taught by a teacher. This method emphasizes active learning, critical thinking, problem-solving and exploration.
                            </p>
                        </div>
                        <div className="lg:w-1/2 mt-8 lg:mt-0">
                            <div className="flex justify-center flex-col items-center bg-purple-100 p-6 rounded-lg shadow-lg">
                                <h2 className="text-2xl font-bold text-yellow-500 mb-4">Key Features:</h2>
                                <ul className="list-disc text-lg text-gray-700">
                                    <li>Encourages active learning</li>
                                    <li>Fosters critical thinking</li>
                                    <li>Promotes problem-solving skills</li>
                                    <li>Engages students in exploration</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <section class="bg-purple-100 py-20">
                    <div class="container mx-auto px-4">
                        <div class="max-w-2xl mx-auto text-center">
                            <h2 class="text-3xl font-bold mb-6">Intended audience & Utility of the site</h2>
                            <p class="text-gray-600 mb-12">Our site helps not only students, but also teachers to save time with ready-made assignments. We inform you that although the site is primarily designed for 9th grade, it can be used in higher grades. We hope it will be useful for you
                            </p>
                        </div>
                        <div class="flex flex-wrap -mx-4 mt-12">
                            <div class="w-full md:w-1/3 px-4 mb-8">
                                <div class="rounded-md bg-white shadow-md p-8">
                                    <div class="text-4xl font-bold text-purple-600 mb-4">01</div>
                                    <h3 class="text-2xl text-yellow-500 font-bold mb-4">For students</h3>
                                    <p class="text-gray-600 mb-4">Students are given the opportunity to explore topics, ask questions, experiment and draw their own conclusions.
Discovery learning can be a powerful tool for promoting deeper understanding and retention of information. It allows students to engage with the material in a more personal and meaningful way, leading to better long-term retention.</p>
                                </div>
                            </div>
                            <div class="w-full md:w-1/3 px-4 mb-8">
                                <div class="rounded-md bg-white shadow-md p-8">
                                    <div class="text-4xl font-bold text-purple-600 mb-4">02</div>
                                    <h3 class="text-2xl text-yellow-500 font-bold mb-4">For teachers</h3>
                                    <p class="text-gray-600 mb-4">Our website provides ready-made educational materials and assignments, saving teachers valuable time. It also offers tools for monitoring student progress and adapting materials to their needs. This enables teachers to focus on individual student support and ensures more effective learning.</p>
                                </div>
                            </div>

                            <div class="w-full md:w-1/3 px-4 mb-8">
                                <div class="rounded-md bg-white shadow-md p-8">
                                    <div class="text-4xl font-bold text-purple-600 mb-4">03</div>
                                    <h3 class="text-2xl text-yellow-500 font-bold mb-4">For Self-Learners</h3>
                                    <p class="text-gray-600 mb-4">Our website offers the opportunity for self-paced English language learning through interactive materials and assignments. This helps develop self-directed learning skills, self-regulation and self-organization, which are crucial aspects of successful learning at any stage.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="bg-zinc-50 mt-8 overflow-hidden">
                    <div class="max-w-screen-xl 2xl:max-w-screen-3xl px-8 md:px-12 mx-auto py-12 lg:py-24 space-y-24 h-svh flex flex-col justify-center">
                    <div class="flex flex-col sm:flex-row mx-auto">
                    <a href="#_"> <img src="./assets/img4.jpeg" class="rounded-xl  rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom" alt="#_"/> </a><a href="#_"> <img src="./assets/img3.jpeg" class="rounded-xl  -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom" alt="#_"/> </a><a href="#_"> <img src="./assets/img2.jpeg" class="rounded-xl  rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom" alt="#_"/> </a><a href="#_"> <img src="./assets/img1.jpeg" class="rounded-xl  -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom" alt="#_"/> </a>

                    </div> 
                    
                    </div>
                    </section>
                <section className=" sm:py-16 bg-purple-100 mb-8 lg:py-12">
    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Explore Common Questions</h2>
        </div>
        <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
            {['1', '2', '3', '4'].map((questionId) => (
                <div key={questionId} className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                    <button type="button" onClick={() => toggleAnswer(`question${questionId}`)} className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                        <span className="flex text-lg text-purple-600 font-bold text-black">{
                            questionId === '1' ? 'What educational approach does our site support?' :
                            questionId === '2' ? 'Who benefits from using the site?' :
                            questionId === '3' ? 'What grade level is the site designed for?' :
                            'How does the site facilitate learning?'
                        }</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`w-6 h-6 text-gray-400 transform ${answersVisible[`question${questionId}`] ? 'rotate-0' : 'rotate-180'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                    </button>
                    <div style={{display: answersVisible[`question${questionId}`] ? 'block' : 'none'}} className="px-4 pb-5 sm:px-6 sm:pb-6">
                        <p>{
                            questionId === '1' ? "Our site supports discovery learning, which empowers students to explore concepts independently, fostering active learning, critical thinking and problem-solving skills." :
                            questionId === '2' ? "Both students and teachers benefit from our site. Students gain the opportunity to engage with material in a personal and meaningful way, leading to better long-term retention. Teachers save time with ready-made assignments and can utilize the platform to enhance classroom instruction." :
                            questionId === '3' ? "While primarily designed for 9th grade, our site can be utilized by students in higher grades as well, providing a flexible learning environment adaptable to various educational levels." :
                            "Our site provides comprehensive coverage of grammar, vocabulary and skills through engaging content specifically designed for teenagers. Additionally, it offers clear instructions and feedback on assignments, promoting independent learning both in the classroom and at home."
                        }</p>
                    </div>
                </div>
            ))}
        </div>
        <p className="text-center text-gray-600 textbase mt-9">Still have questions?
            <span className="cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline">Contact our support</span>
        </p>
    </div>
</section>


        </div>
    );
}

export default Home;