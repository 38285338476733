import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';

const words = [
  { id: 'a', text: 'was accused' },
  { id: 'b', text: 'cleans' },
  { id: 'c', text: 'learn' },
  { id: 'd', text: 'cancelled' },
  { id: 'e', text: 'were warned' },
  { id: 'f', text: 'repaired' },
  { id: 'g', text: 'were warned' },
  { id: 'h', text: 'are visited' },
  { id: 'i', text: 'is not used' },
  { id: 'j', text: 'was built' },
  { id: 'k', text: 'is not used' },
  { id: 'l', text: 'is used' },
];

const sentences = [
  { id: 's1', text: 'A new office ___', correct: 'was built' },
  { id: 's2', text: 'People ___ different foreign languages', correct: 'learn' },
  { id: 's3', text: 'Her parents ___ twice a month', correct: 'are visited' },
  { id: 's4', text: 'They ___ not to go out alone', correct: 'were warned' },
  { id: 's5', text: 'Somebody ___ the room every day', correct: 'cleans' },
  { id: 's6', text: 'This road ___ much', correct: 'is not used' },
  { id: 's7', text: 'I ___ of stealing money', correct: 'was accused' },
  { id: 's8', text: 'My computer ___ by somebody', correct: 'is used' },
  { id: 's9', text: 'They ___ all flights because of fog', correct: 'cancelled' },
];

function Lesson7() {
  const [wordList, setWordList] = useState(words);
  const [sentenceList, setSentenceList] = useState(sentences.map(s => ({ ...s, selected: null })));
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'word-cloud' && destination.droppableId.startsWith('s')) {
      // Если слово перетаскивается из облака в предложение
      const wordIndex = source.index;
      const sentenceId = destination.droppableId;
      
      // Добавляем слово в предложение и удаляем из облака
      const newSentenceList = sentenceList.map(s => {
        if (s.id === sentenceId) {
          return { ...s, selected: wordList[wordIndex] };
        }
        return s;
      });

      const newWordList = [...wordList];
      newWordList.splice(wordIndex, 1);

      setSentenceList(newSentenceList);
      setWordList(newWordList);
    } else if (source.droppableId.startsWith('s') && destination.droppableId === 'word-cloud') {
      // Если слово перетаскивается обратно в облако
      const sentenceIndex = sentenceList.findIndex(s => s.id === source.droppableId);
      const wordToMove = sentenceList[sentenceIndex].selected;

      if (wordToMove) {
        const newSentenceList = [...sentenceList];
        newSentenceList[sentenceIndex].selected = null;

        setSentenceList(newSentenceList);
        setWordList([...wordList, wordToMove]);
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const getSentenceClass = (sentence) => {
    if (!isSubmitted) return '';

    return sentence.selected && sentence.selected.text === sentence.correct 
      ? 'bg-green-200' 
      : 'bg-red-200';
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col justify-center items-center my-8"
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
          <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">
            Sentence Puzzle
          </h1>

          <div className="flex justify-center mb-8">
            <Droppable droppableId="word-cloud">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex flex-wrap gap-4 bg-purple-100 p-4 rounded-lg border w-full justify-center"
                >
                  {wordList.map((word, index) => (
                    <Draggable key={word.id} draggableId={word.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white p-4 rounded-lg border mb-2"
                        >
                          {word.text}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="flex flex-col gap-4">
            {sentenceList.map((sentence, index) => (
              <Droppable key={sentence.id} droppableId={sentence.id}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`bg-white p-4 rounded-lg border mb-2 flex items-center justify-center  ${getSentenceClass(sentence)}`}
                  >
                    <span>{sentence.text.split('___')[0]}</span> 
                    <span>   _________ </span>
                    <span>{sentence.text.split('___')[1]}</span>

                    {sentence.selected ? (
                      <Draggable draggableId={sentence.selected.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white p-4 rounded-lg border"
                          >
                            {sentence.selected.text}
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div className="w-24 bg-purple-200 h-8 rounded" />
                    )}

                    {provided.placeholder}
                  </div>
                )}
                </Droppable>
            ))}
          </div>

          <div className="text-center mt-8">
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
            >
              Submit
            </button>
          </div>
        </div>
      </DragDropContext>
    </motion.div>
  );
}

export default Lesson7;
