import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';

const initialQuestions = [
    {
        id: 1,
        text: "My Dad was ___ angry that I knew I’d better disappear for a while.",
        options: ["so", "such"],
        correct: "so",
        explanation: "is generally used with adjectives and adverbs. It implies a high degree of the adjective or adverb used, often resulting in a consequence."
    },
    {
        id: 2,
        text: "The journey was ___ long that we wanted to return.",
        options: ["so", "such"],
        correct: "so",
        explanation: "is used with adjectives and indicates a high degree of a particular quality."
    },
    {
        id: 3,
        text: "They were ___ beautiful earrings that I decided I had to get them.",
        options: ["so", "such"],
        correct: "such",
        explanation: "is used with nouns or noun phrases. With countable nouns, it takes an article ('such a'/'such an')."
    },
    {
        id: 4,
        text: "It was ___ windy that they couldn’t stand up!",
        options: ["so", "such"],
        correct: "so",
        explanation: "is used with adjectives, indicating a high degree or level, often leading to a consequence."
    },
    {
        id: 5,
        text: "Her pies were ___ delicious, that I asked for another helping.",
        options: ["so", "such"],
        correct: "so",
        explanation: "is used with adjectives to indicate a high degree, often leading to a specific outcome."
    },
    {
        id: 6,
        text: "They are ___ noisy neighbors that we can’t sleep at night.",
        options: ["so", "such"],
        correct: "such",
        explanation: "is used with noun phrases, indicating the type of noun. It often suggests an extreme case."
    },
    {
        id: 7,
        text: "They had ___ wonderful holiday in Italy.",
        options: ["so", "such"],
        correct: "such a",
        explanation: "is used with countable nouns, often suggesting the characteristic of the noun. It requires an article ('such a'/'such an')."
    },
    {
        id: 8,
        text: "It was ___ waste of time!",
        options: ["so", "such"],
        correct: "such a",
        explanation: "is used with countable nouns and requires an article ('such a'/'such an')."
    },
    {
        id: 9,
        text: "The film was ___ boring!",
        options: ["so", "such"],
        correct: "so",
        explanation: "is used with adjectives, indicating a high degree or level of a particular quality."
    },
    {
        id: 10,
        text: "The water in the rivers is ___ dirty.",
        options: ["so", "such"],
        correct: "so",
        explanation: "is used with adjectives to indicate a high degree of a particular characteristic."
    },
];

function Lesson2() {
    // Используем стейт для отслеживания начальных вопросов и боксов "so" и "such"
    const [questionBox, setQuestionBox] = useState(initialQuestions);
    const [soBox, setSoBox] = useState([]);
    const [suchBox, setSuchBox] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const handleDragEnd = (result) => {
      const { source, destination } = result;
  
      if (!destination) {
        return;
      }
  
      // Если перемещение внутри одного и того же бокса, просто меняем порядок
      if (source.droppableId === destination.droppableId) {
        const updateList = (list) => {
          const newList = Array.from(list);
          const [movedItem] = newList.splice(source.index, 1);
          newList.splice(destination.index, 0, movedItem);
          return newList;
        };
  
        if (source.droppableId === 'question-box') {
          setQuestionBox(updateList(questionBox));
        } else if (source.droppableId === 'so') {
          setSoBox(updateList(soBox));
        } else if (source.droppableId === 'such') {
          setSuchBox(updateList(suchBox));
        }
        return;
      }
  
      // Если перемещение между разными боксами, удаляем из источника и добавляем в назначение
      let movedItem;
      if (source.droppableId === 'question-box') {
        const newList = Array.from(questionBox);
        [movedItem] = newList.splice(source.index, 1);
        setQuestionBox(newList);
      } else if (source.droppableId === 'so') {
        const newList = Array.from(soBox);
        [movedItem] = newList.splice(source.index, 1);
        setSoBox(newList);
      } else if (source.droppableId === 'such') {
        const newList = Array.from(suchBox);
        [movedItem] = newList.splice(source.index, 1);
        setSuchBox(newList);
      }
  
      // Добавляем в назначение
      if (destination.droppableId === 'so') {
        const newList = Array.from(soBox);
        newList.splice(destination.index, 0, movedItem);
        setSoBox(newList);
      } else if (destination.droppableId === 'such') {
        const newList = Array.from(suchBox);
        newList.splice(destination.index, 0, movedItem);
        setSuchBox(newList);
      }
    };

    const handleSubmit = () => {
        setIsSubmitted(true);
      };
    
      const getAnswerClass = (item, boxType) => {
        if (!isSubmitted) return '';
    
        if (boxType === item.correct) {
          return 'bg-green-200';
          
        } else {
          return 'bg-red-200';
        }
      };
  
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-center items-center my-8"
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Drag Sentences to "so" or "such"
            </h1>
  
            <div className="flex justify-between gap-4">
              <Droppable droppableId="question-box">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-1/3 bg-purple-100 p-4 rounded-lg border"
                  >
                    <p className="text-center">Questions</p>
                    {questionBox.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white p-4 rounded-lg border mb-2"
                        >
                          {item.text}
                        </div>
                      )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
  
              <Droppable droppableId="so">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-1/3 bg-purple-200 p-4 rounded-lg border"
                  >
                    <p className="text-center">Drop here if "so"</p>
                    {soBox.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`bg-white p-4 rounded-lg border mb-2 ${getAnswerClass(item, 'so')}`}
                          >
                            {item.text} <br></br>
                            {isSubmitted && (
                              item.correct === "so" ? (
                                <p className="text-green-600 flex items-center text-sm">
                                  Correct! Great job! {item.explanation}
                                </p>
                              ) : (
                                <p className="text-red-600 flex items-center text-sm">
                                  Not quite! Try again! The correct answer: {item.correct}. {item.explanation}
                                </p>
                              )
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>

  
              <Droppable droppableId="such">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-1/3 bg-purple-200 p-4 rounded-lg border"
                  >
                    <p className="text-center">Drop here if "such"</p>
                    {suchBox.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`bg-white p-4 rounded-lg border mb-2 ${getAnswerClass(item, 'such')}`}
                          >
                            {item.text} <br></br>
                            {isSubmitted && (
                              item.correct === "such" ? (
                                <p className="text-green-600 flex items-center text-sm">
                                  Correct! Great job! {item.explanation}
                                </p>
                              ) : (
                                <p className="text-red-600 flex items-center text-sm">
                                  Not quite! Try again! The correct answer: {item.correct}. {item.explanation}
                                </p>
                              )
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="text-center mt-8">
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
            >
              Submit
            </button>
          </div>
          </div>
        </DragDropContext>
      </motion.div>
    );
  }
  
  export default Lesson2;