import React, { useState } from 'react';
import { motion } from 'framer-motion';

const sentenceData = [
  {
    id: 'q1',
    text: 'If I ___ more money, I\'d travel more.',
    options: ['had', 'would have', 'have'],
    correct: 'had',
    explanation: 'In the second conditional, "had" indicates a hypothetical situation with a possible consequence.',
  },
  {
    id: 'q2',
    text: 'What would you do if you ___ a lot of money in the street?',
    options: ['found', 'find', 'finds'],
    correct: 'found',
    explanation: 'In the second conditional, "found" is a hypothetical condition.',
  },
  {
    id: 'q3',
    text: 'A lot of health problems could be prevented if people ___ better.',
    options: ['ate', 'eat', 'eats'],
    correct: 'ate',
    explanation: 'In the second conditional, "ate" indicates a hypothetical condition with a possible outcome.',
  },
  {
    id: 'q4',
    text: 'If I had her number, I ___ her; but I don\'t have it.',
    options: ['would call', 'will call', 'calls'],
    correct: 'would call',
    explanation: 'In the second conditional, "would call" indicates a hypothetical action based on an imagined condition.',
  },
  {
    id: 'q5',
    text: 'If he ___ more diligent, he would have finished the project on time.',
    options: ['were', 'is', 'was'],
    correct: 'were',
    explanation: 'In the second conditional, "were" is used with a singular subject to indicate a hypothetical condition.',
  },
  {
    id: 'q6',
    text: 'If they had a map, they ___ get lost.',
    options: ['would not', 'will not', 'do not'],
    correct: 'would not',
    explanation: 'In the second conditional, "would not" expresses a hypothetical negative outcome based on an imagined condition.',
  },
  {
    id: 'q7',
    text: 'If she were more confident, she ___ pursue her dreams.',
    options: ['would', 'will', 'is'],
    correct: 'would',
    explanation: 'In the second conditional, "would" indicates a hypothetical outcome based on a condition.',
  },
  {
    id: 'q8',
    text: 'If you ___ the invitation, you would have a chance to attend the event.',
    options: ['accept', 'accepted', 'accepts'],
    correct: 'accepted',
    explanation: 'In the second conditional, "accepted" indicates a hypothetical condition in the past.',
  },
  {
    id: 'q9',
    text: 'If the weather ___ better, we would go for a picnic.',
    options: ['were', 'was', 'is'],
    correct: 'were',
    explanation: 'In the second conditional, "were" expresses a hypothetical condition.',
  },
  {
    id: 'q10',
    text: 'If you had more time, you ___ be able to finish the book.',
    options: ['would', 'will', 'are'],
    correct: 'would',
    explanation: 'In the second conditional, "would" indicates a hypothetical outcome based on a condition.',
  },
];

function Lesson6() {
  const [selectedAnswers, setSelectedAnswers] = useState(
    sentenceData.reduce((acc, sentence) => {
      acc[sentence.id] = '';
      return acc;
    }, {})
  );

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleAnswerSelect = (sentenceId, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [sentenceId]: value,
    });
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const getSentenceClass = (sentenceId) => {
    if (!isSubmitted) return '';

    const isCorrect =
      selectedAnswers[sentenceId] ===
      sentenceData.find((s) => s.id === sentenceId).correct;

    return isCorrect ? 'bg-green-200' : 'bg-red-200';
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col justify-center items-center my-8"
    >
      <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
        <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">
          Conditional Sentences Exercise
        </h1>

        <div className="flex flex-col gap-6">
          {sentenceData.map((sentence) => (
            <div
              key={sentence.id}
              className={`bg-white p-4 rounded-lg border flex flex-col ${getSentenceClass(sentence.id)}`}
            >
              <div className="flex items-center gap-2">
                <span>{sentence.text.split('___')[0]}</span>
                <select
                  value={selectedAnswers[sentence.id]}
                  onChange={(e) => handleAnswerSelect(sentence.id, e.target.value)}
                  className="border bg-purple-200 px-4 py-2 rounded-lg"
                >
                  <option value="" disabled>
                    Choose an option
                  </option>
                  {sentence.options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <span>{sentence.text.split('___')[1]}</span>
              </div>

              {isSubmitted && (
                <div className="mt-4 text-gray-700">
                  <strong>Explanation:</strong> {sentence.explanation}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="text-center mt-8">
          <button
            onClick={handleSubmit}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
          >
            Submit
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default Lesson6;
