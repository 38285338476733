import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes } from 'react-icons/fa';

  
const verbs = [
    { infinitive: "run", pastForm: "ranned", isCorrect: false },
    { infinitive: "eat", pastForm: "ate", isCorrect: true },
    { infinitive: "Be", pastForm: "was/were", isCorrect: true },
    { infinitive: "Have", pastForm: "has", isCorrect: false },
    { infinitive: "Cook", pastForm: "Cooked", isCorrect: true },
    { infinitive: "Fall", pastForm: "Falled", isCorrect: false },
    { infinitive: "Watch", pastForm: "Watched", isCorrect: true },
    { infinitive: "Go", pastForm: "Went", isCorrect: true },
    { infinitive: "Drink", pastForm: "Drinked", isCorrect: false },
    { infinitive: "Work", pastForm: "Worked", isCorrect: true },
    { infinitive: "Give", pastForm: "gave", isCorrect: true },
    { infinitive: "Buy", pastForm: "bought", isCorrect: true },
    { infinitive: "Play", pastForm: "played", isCorrect: true },
    { infinitive: "Break", pastForm: "Broken", isCorrect: false },
  ];
const Lesson9 = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);
    const [correctCount, setCorrectCount] = useState(0);
    const [gameOver, setGameOver] = useState(false);
  
    const handleCardFlip = () => {
      setIsFlipped(!isFlipped);
    };
  
    const handleAnswer = (isCorrect) => {
        if (isCorrect) {
          setCorrectCount(correctCount + 1);
        }
        if (currentIndex < verbs.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setIsFlipped(false);
        } else {
          setGameOver(true);
          setIsFlipped(false);
        }
      };
  
    const currentVerb = verbs[currentIndex];
  
    return (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mt-8 mb-8 flex flex-col justify-center items-center"
        >
          <div className="w-full flex flex-col justify-center items-center max-w-4xl bg-white p-8 rounded-xl shadow-xl">
            <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Identify the correct form of verbs</h1>
            {gameOver ? (
              <div className="card flex flex-col justify-center bg-white rounded-lg shadow-lg cursor-pointer">
                <h1 className="text-4xl font-bold p-6">Game Over!</h1>
                <p className="text-xl font-semibold text-orange-500">You got {correctCount} out of {verbs.length} correct.</p>
                {correctCount === verbs.length && <p className="text-lg text-green-600">Well done! You nailed it!</p>}
              </div>
            ) : (
              <motion.div
                className="card justify-center bg-white rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleCardFlip()}
                initial={{ rotateY: 0 }}
                animate={{ rotateY: isFlipped ? 180 : 0 }}
                transition={{ duration: 0.5 }}
              >
                 <h1 className="text-4xl font-bold p-6" style={{ transform: isFlipped ? 'rotateY(180deg)' : 'none' }}>{isFlipped ? currentVerb.pastForm : currentVerb.infinitive}</h1>
              </motion.div>
            )}
            {isFlipped && !gameOver && (
              <div className="mt-4 flex gap-4">
                <button
                  onClick={() => handleAnswer(currentVerb.isCorrect)}
                  className="btn btn-green"
                >
                  <FaCheck />
                </button>
                <button
                  onClick={() => handleAnswer(!currentVerb.isCorrect)}
                  className="btn btn-red"
                >
                  <FaTimes />
                </button>
              </div>
            )}
            
          </div>
        </motion.div>
      );
      
};

export default Lesson9;