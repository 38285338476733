import React from 'react';

function About() {
  return (
    <div>
      <div className="flex flex-col items-center p-6 space-y-6">
        {/* Карточка 1 */}
        <a
          href="#"
          className="flex flex-col items-center bg-white rounded-lg shadow md:flex-row md:max-w-xl hover:bg-purple-100" 
          style={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg h-128 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            src="./assets/member1.JPG"
            alt=""
          />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-yellow-400 dark:text-white">
            Akhmetov Darkhan
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            📚 Kazakh National Pedagogical University's fourth-year student is embracing a global mindset by learning two foreign languages. This pursuit underscores their passion for cultural exchange and communication. As they approach graduation, their proficiency in multiple languages sets them up for success in education, linguistics, or cross-cultural careers.
            </p>

            <div className="flex justify-end mt-4">
            <button
                type="button"
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2"
            >
                <a href="https://api.whatsapp.com/send?phone=87087152471">Contact</a>
            </button>
            </div>
          </div>
        </a>
          

        {/* Карточка 2 */}
        <a
          href="#"
          className="flex flex-col items-center bg-white  rounded-lg shadow md:flex-row md:max-w-xl hover:bg-purple-100"
          style={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <img
            className="object-cover w-fill rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            src="./assets/member3.JPG"
            alt=""
          />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-yellow-400 dark:text-white">
            Bekhozhina Ingkar
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            🎓 This fourth-year student from Kazakh National Pedagogical University is making waves with their passion for linguistics. They are studying two foreign languages and already show great aptitude. As they near graduation, they are excited to apply their knowledge to a career in education, translation, or international relations.
            </p>
            <div className="flex justify-end mt-4">
            <button
                type="button"
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2"
            >
                <a href="https://api.whatsapp.com/send?phone=87783698033">Contact</a>
            </button>
            </div>
          </div>
        </a>

        {/* Карточка 3 */}
        <a
          href="#"
          className="flex flex-col items-center bg-white  rounded-lg shadow md:flex-row md:max-w-xl hover:bg-purple-100"
          style={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <img
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            src="./assets/aru.jpeg"
            alt=""
          />
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-yellow-400 dark:text-white">
            Rakhman Aruzhan
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
            🌟 Meet a fourth-year student at Kazakh National Pedagogical University! This dedicated scholar is learning two foreign languages, indicating a keen interest in linguistics and cultural connections. As they prepare to graduate, they're well on their way to mastering new languages and are eager to put their skills to use in education or related fields.
            </p>
            <div className="flex justify-end mt-4">
            <button
                type="button"
                className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-4 py-2"
            >
                <a href="https://api.whatsapp.com/send?phone=87473364051">Contact</a>
            </button>
            </div>
          </div>
        </a>
      </div>
      
    </div>
  );
}

export default About;
