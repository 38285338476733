import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Lesson10 = () => {
  const [answers, setAnswers] = useState({
    'is cooking': '',
    'are watching': '',
    'is eating': '',
    'is looking': '',
    'is calling': '',
    'are playing': '',
    'are singing': '',
    'is listening': '',
    'is reading': '',
    'is writing': '',
    'is painting': '',
    'is watching': ''
  });

  const correctAnswers = {
    'is cooking': 'Robert',
    'are watching': 'Sam Jess',
    'is eating': 'Jason',
    'is looking': 'Molly',
    'is calling': 'Jenny',
    'are playing': 'Dave Frank',
    'are singing': 'Catherine Heidi',
    'is listening': 'Emma',
    'is reading': 'Sarah',
    'is writing': 'Amy',
    'is painting': 'Jack',
    'is watching': 'Toby'
  };

  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState(null);

  const handleInputChange = (e, action) => {
    setAnswers({
      ...answers,
      [action]: e.target.value
    });
  };

  const handleSubmit = () => {
    let score = 0;
    for (const action in answers) {
      if (answers[action].toLowerCase() === correctAnswers[action].toLowerCase()) {
        score++;
      }
    }
    setResult(score);
    setSubmitted(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="mt-8 mb-8 flex flex-col justify-center items-center"
    >
      <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl relative">
        <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Identifying Actions of Individuals in an Image!</h1>
        {/* Your image goes here */}
        <img src="./assets/doingaction.jpeg" alt="People Doing Actions" className="max-w-full rounded-lg shadow-lg mb-6" />
        
        {/* Words */}
        <div className="flex flex-wrap justify-center">
          {Object.keys(answers).map((action, index) => (
            <div key={index} className="m-4">
              <p className="text-lg font-semibold mb-2">{action}</p>
              <input
                type="text"
                value={answers[action]}
                onChange={(e) => handleInputChange(e, action)}
                className={`border rounded-lg p-2 ${submitted && answers[action].toLowerCase() === correctAnswers[action].toLowerCase() ? 'bg-green-100' : submitted && answers[action] !== correctAnswers[action] ? 'bg-red-100' : 'bg-purple-100'}`}
                disabled={submitted}
              />
              {submitted && (
                <p className="text-sm text-gray-500 mt-1">Correct answer: {correctAnswers[action]}</p>
              )}
            </div>
          ))}
        </div>

        {/* Submit button */}
        <motion.button
          onClick={handleSubmit}
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mt-4"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={submitted}
        >
          {submitted ? 'Submitting...' : 'Submit'}
        </motion.button>

        {/* Display results with animation */}
        {submitted && (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.5 }}
    className="mt-4 p-4 bg-white shadow-lg rounded-lg"
  >
    <p className="text-3xl text-red-500 font-bold mb-2">
      Score: {result} / {Object.keys(correctAnswers).length}
    </p>
    <h4 className="mt-2 text-xl text-gray-800">
      The present continuous tense is commonly used when describing pictures. This tense helps convey what is happening in the image at the moment it is being viewed. Here are some vivid examples:
    </h4>
    <ul className="list-disc list-inside mt-4 text-gray-700 space-y-2">
      <li className="flex justify-center items-center">
        <svg className="w-4 h-4 mr-2 text-purple-500" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0L9.8 7.6H2.3L8.1 11.7L6.3 18.9L12 14.8L17.7 18.9L15.9 11.7L21.7 7.6H14.2L12 0Z" /></svg>
        The children are playing in the park.
      </li>
      <li className="flex justify-center items-center">
        <svg className="w-4 h-4 mr-2 text-purple-500" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0L9.8 7.6H2.3L8.1 11.7L6.3 18.9L12 14.8L17.7 18.9L15.9 11.7L21.7 7.6H14.2L12 0Z" /></svg>
        A woman is reading a book on a bench.
      </li>
      <li className="flex justify-center items-center">
        <svg className="w-4 h-4 mr-2 text-purple-500" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0L9.8 7.6H2.3L8.1 11.7L6.3 18.9L12 14.8L17.7 18.9L15.9 11.7L21.7 7.6H14.2L12 0Z" /></svg>
        The sun is setting behind the mountains.
      </li>
      <li className="flex justify-center items-center">
        <svg className="w-4 h-4 mr-2 text-purple-500" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0L9.8 7.6H2.3L8.1 11.7L6.3 18.9L12 14.8L17.7 18.9L15.9 11.7L21.7 7.6H14.2L12 0Z" /></svg>
        A dog is running across the field.
      </li>
      <li className="flex justify-center items-center">
        <svg className="w-4 h-4 mr-2 text-purple-500" fill="currentColor" viewBox="0 0 24 24"><path d="M12 0L9.8 7.6H2.3L8.1 11.7L6.3 18.9L12 14.8L17.7 18.9L15.9 11.7L21.7 7.6H14.2L12 0Z" /></svg>
        Two people are talking by the river.
      </li>
    </ul>
    <h4 className="mt-4 text-xl text-gray-800">
      Using the present continuous tense provides a vivid, dynamic description of the actions and events depicted in the picture.
    </h4>
  </motion.div>
)}


      </div>
    </motion.div>
  );
};

export default Lesson10;
