import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Wheel } from 'react-custom-roulette';

const words = [
  { id: 'a', text: 'Ago' },
  { id: 'b', text: 'Already' },
  { id: 'c', text: 'Always' },
  { id: 'd', text: 'Before' },
  { id: 'e', text: 'Ever' },
  { id: 'f', text: 'For' },
  { id: 'g', text: 'Just' },
  { id: 'h', text: 'Never' },
  { id: 'i', text: 'Since' },
  { id: 'j', text: 'Yet' },
];

const sentences = [
  {
    id: 's1',
    text: 'She finished her homework an hour ___',
    correct: 'Ago',
    explanation: '“Ago" indicates a specific point in the past, referring to one hour before the current time.',
  },
  {
    id: 's2',
    text: 'They have ___ visited London three times.',
    correct: 'Already',
    explanation: '"Already" suggests completion or experience before the present moment, indicating that the action of visiting London has occurred three times in the past.',
  },
  {
    id: 's3',
    text: 'Sarah ___ brushes her teeth before bed.',
    correct: 'Always',
    explanation: '"Always" indicates a habitual action that occurs regularly, emphasizing that Sarah consistently brushes her teeth before going to bed.',
  },
  {
    id: 's4',
    text: 'He had never tried sushi ___ last night.',
    correct: 'Before',
    explanation: '"Before" is used to indicate a point in time earlier than the specified time, suggesting that until last night, he had not tried sushi.',
  },
  {
    id: 's5',
    text: 'Have you ___ been to Paris?',
    correct: 'Ever',
    explanation: '"Ever" is used in questions to inquire about any experience in ones lifetime, asking whether the person has ever visited Paris at any time.',
  },
  {
    id: 's6',
    text: 'They have been studying English ___ five years',
    correct: 'For',
    explanation: '"For" is used to indicate the duration of an action or situation, specifying that they have been studying English continuously over a period of five years',
  },
  {
    id: 's7',
    text: 'I have ___ finished my lunch',
    correct: 'Just',
    explanation: '"Just" indicates a very recent action, suggesting that the speaker has finished their lunch only a short time before the current moment.',
  },
  {
    id: 's8',
    text: 'He has ___ traveled abroad.',
    correct: 'Never',
    explanation: '"Never" indicates the absence of an experience at any time in one is life, emphasizing that he has not traveled abroad at any point.',
  },
  {
    id: 's9',
    text: 'She has been living in New York ___ 2010.',
    correct: 'Since',
    explanation: '"Since" indicates the starting point of an action or situation, specifying that she started living in New York in 2010 and continues to do so.',
  },
  {
    id: 's10',
    text: 'Have you finished your homework ___?',
    correct: 'Yet',
    explanation: '"Yet" is used in questions to inquire about completion up to the present moment, asking whether the person has finished their homework at this time.',
  },
];

const questions = [
  "Have you ever made pancakes?",
  "Have you ever sung karaoke in a different language?",
  "Have you ever tried a food you couldn't pronounce?",
  "Have you ever accidentally ended up in a different city or country?",
  "Have you ever found a message in a bottle?",
  "Have you ever had a conversation with a stranger that changed your perspective?",
  "Have you ever discovered a hidden talent you never knew you had?",
  "Have you ever participated in a flash mob?",
  "Have you ever experienced a moment that felt like it belonged in a movie?",
];

function Lesson3() {
  const [wordList, setWordList] = useState(words);
  const [sentenceList, setSentenceList] = useState(
    sentences.map((s) => ({ ...s, selected: null }))
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [currentWheelData, setCurrentWheelData] = useState(
    questions.map((_, index) => ({
      option: `Question ${index + 1}`,
      style: { backgroundColor: index % 2 === 0 ? '#f8b400' : '#f85f73', textColor: 'white' }
    }))
  );

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'word-cloud' && destination.droppableId.startsWith('s')) {
      const wordIndex = source.index;
      const sentenceId = destination.droppableId;

      const newSentenceList = sentenceList.map((s) => {
        if (s.id === sentenceId) {
          return { ...s, selected: wordList[wordIndex] };
        }
        return s;
      });

      const newWordList = [...wordList];
      newWordList.splice(wordIndex, 1);

      setSentenceList(newSentenceList);
      setWordList(newWordList);
    } else if (
      source.droppableId.startsWith('s') &&
      destination.droppableId === 'word-cloud'
    ) {
      const sentenceIndex = sentenceList.findIndex(
        (s) => s.id === source.droppableId
      );
      const wordToMove = sentenceList[sentenceIndex].selected;

      if (wordToMove) {
        const newSentenceList = [...sentenceList];
        newSentenceList[sentenceIndex].selected = null;

        setSentenceList(newSentenceList);
        setWordList([...wordList, wordToMove]);
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  const getSentenceClass = (sentence) => {
    if (!isSubmitted) return '';

    return sentence.selected && sentence.selected.text === sentence.correct
      ? 'bg-green-200'
      : 'bg-red-200';
  };

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * currentWheelData.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const handleStopSpinning = () => {
    const newWheelData = currentWheelData.filter((_, index) => index !== prizeNumber);
    setCurrentWheelData(newWheelData);
    setMustSpin(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col justify-center items-center my-8"
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
          <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">
            Sentence Puzzle
          </h1>

          <div className="flex justify-center mb-8">
            <Droppable droppableId="word-cloud">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex flex-wrap gap-4 bg-purple-100 p-4 rounded-lg border w-full justify-center"
                >
                  {wordList.map((word, index) => (
                    <Draggable key={word.id} draggableId={word.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white p-4 rounded-lg border mb-2"
                        >
                          {word.text}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="flex flex-col gap-4">
            {sentenceList.map((sentence, index) => (
              <Droppable key={sentence.id} droppableId={sentence.id}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`bg-white p-4 rounded-lg border mb-2 flex items-center justify-center ${getSentenceClass(sentence)}`}
                  >
                    <span>{sentence.text.split('___')[0]}</span>
                    <span>______</span>
                    <span>{sentence.text.split('___')[1]}</span>

                    {sentence.selected ? (
                        <Draggable
                        draggableId={sentence.selected.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white p-4 rounded-lg border"
                          >
                            {sentence.selected.text}
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div className="w-24 bg-purple-200 h-8 rounded" />
                    )}

                    {provided.placeholder}

                    {isSubmitted && (
                      <div className="ml-4 text-sm">
                        {sentence.selected && sentence.selected.text === sentence.correct ? (
                          <p className="text-green-600 flex items-center">
                            <FaCheck className="mr-2" /> Correct! {sentence.explanation}
                          </p>
                        ) : (
                          <p className="text-red-600 flex items-center">
                            <FaTimes className="mr-2" /> Not quite! The correct answer was: {sentence.correct}. {sentence.explanation}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            ))}
          </div>

          <div className="text-center mt-8">
            <button
              onClick={handleSubmit}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
            >
              Submit
            </button>
          </div>
        </div>
      </DragDropContext>

      {/* Spinning Wheel Component */}
      <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl mt-12 flex flex-col items-center">
        <h1 className="text-2xl font-bold text-center text-purple-800 mb-4">
          Spinning Wheel
        </h1>
        <div className="flex justify-center">
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={currentWheelData}
            onStopSpinning={handleStopSpinning}
            backgroundColors={['#3e3e3e', '#df3428']}
            textColors={['#ffffff']}
            radiusLineColor={'#fff'}
            outerBorderColor={'#fff'}
            innerBorderColor={'#fff'}
            fontSize={20}
            textDistance={70}
            spinDuration={0.6}
          />
        </div>
        <div className="text-center mt-4">
          <button
            onClick={handleSpinClick}
            disabled={mustSpin || currentWheelData.length === 0}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
          >
            Spin the Wheel
          </button>
        </div>
        {prizeNumber !== null && !mustSpin && (
          <div className="mt-4 text-center text-xl text-purple-800">
            <p className="text-yellow-500 font-bold">Selected Question:</p>{' '}
            {questions[prizeNumber]}
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default Lesson3;

