import React from 'react';
import { motion } from 'framer-motion';
const listItems = [
  "Start by exploring our assignments covering grammar, vocabulary and skills.",
  "Engage with interactive exercises designed for both classroom and online use.",
  "Delve into reading materials, quizzes and audiovisual resources to enhance your learning.",
  "Make use of our comprehensive content to master English effectively."
];

const Guide = () => {
  return (
    <div className="flex justify-center items-center mt-8 mb-8">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="bg-white w-full max-w-4xl rounded-lg shadow-lg p-8 flex flex-col items-center gap-4"
      >
        <div className="flow-start mb-8">
          <h1 className="text-3xl font-bold text-purple-800 mb-4">The book we used for the tasks</h1>
        </div>
        <div className='flex flex-row justify-center items-center gap-8'>
          <img src='./assets/book.jpeg' alt="Book cover" className="w-106 h-96 rounded-lg border-2" />
          <div>
            <h1 className="text-2xl font-bold text-yellow-500 mb-4">Laser Student's Book</h1>
            <p className="text-lg text-gray-700">
            Laser Student's Book is lively motivating and interesting, specifically designed for teenagers.  They provide comprehensive coverage of grammar, vocabulary and pronunciation as well as focusing regularly on exam-type tasks. They come with a CD-ROM that reinforces the structures and vocabulary learned in each unit.
            </p>
          </div>
        </div>

        <div>
          <div className='flex flex-row justify-center items-center gap-8'>
            <img src='./assets/intro.jpeg' alt="Book cover" className="w-56 h-72 rounded-lg border-2" />
            <img src='./assets/intro1.jpeg' alt="Book cover" className="w-56 h-72 rounded-lg border-2" />
          </div>
          <br />
          <p className="text-lg text-gray-700 mb-4">
            This application contains assignments on various topics that 9th grade students study. You can use them at school in the classroom and also at home as an online English trainer. In all tasks at the top it is written what to do and how, follow the instructions. After you have answered, the correct answers will appear on the screen.
          </p>
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">Step-by-Step Guide</h2>
          <div className="flex items-center justify-center relative">
            
            <div className="flex flex-col gap-8 w-full max-w-lg">
              {listItems.map((item, index) => (
                <motion.div
                  key={index}
                  className="relative flex items-center gap-4"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 * index + 0.6, duration: 0.5 }}
                >
                  <div className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center z-20">
                    <span className="text-white">{index + 1}</span>
                  </div>
                  <p className="text-lg text-gray-700">{item}</p>
                </motion.div>
              ))}
            </div>
          </div>

        </div>
      </motion.div>
    </div>
  );
};

export default Guide;
