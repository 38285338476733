import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes } from 'react-icons/fa';

// Вопросы Multiple Choice с радиокнопками
const multipleChoiceQuestions = [
  {
    id: '1',
    text: "Choose the correct first conditional sentence",
    options: ["If you will leave early, you will avoid traffic.", "If you leave early, you will avoid traffic.", "If you leaving early, you will avoid traffic."],
    correct: "If you leave early, you will avoid traffic.",
    explanation: 'This sentence follows the correct structure of the first conditional. The "if" clause uses the present simple tense ("leave") and the result clause uses "will" + base verb ("will avoid"). This indicates that leaving early increases the likelihood of avoiding traffic.',
  },
  {
    id: '2',
    text: "Which of the following is NOT a first conditional sentence?",
    options: ["If you eat too much sugar, you will gain weight.", "If I had known, I would have helped you.", "if you don't water the plants, they will die."],
    correct: "If I had known, I would have helped you.",
    explanation: 'This sentence is not a first conditional, but rather a third conditional, which describes hypothetical situations in the past. It uses the past perfect tense in the condition ("If I had known") and "would have" + past participle in the result clause ("would have helped"). It indicates something that did not happen but could have happened under a different past condition.',
  },
  {
    id: '3',
    text: "Select the sentence that best fits the first conditional structure:",
    options: ["If he doesn't study, he will fail the exam.", "If he doesn't study, he failed the exam.", "If he doesn't study, he would fail the exam."],
    correct: "If he doesn't study, he will fail the exam.",
    explanation: 'This sentence follows the correct first conditional structure. The condition ("If he does not study") uses the present simple tense and the result ("he will fail the exam") uses "will" + base verb, indicating a likely or probable outcome if the condition is met.',
  },
];

// Вопросы True/False с выпадающим списком
const trueFalseQuestions = [
  {
    id: '1',
    text: "If it rains, the match will be cancelled.",
    correct: "True",
    explanation: 'This sentence follows the first conditional structure. The condition ("if it rains") uses the present simple tense and the result clause ("will be cancelled") uses "will" + base verb, indicating a probable outcome based on a given condition. This is characteristic of the first conditional.'
  },
  {
    id: '2',
    text: "If you will do it, you will succeed.",
    correct: "False",
    explanation: 'This sentence is not a first conditional because it uses "will" in the "if" clause. This suggests a sense of willingness or prediction rather than a condition leading to a probable outcome. The correct structure for the first conditional uses the present simple tense in the "if" clause to indicate a condition that triggers a likely outcome. Using "will" in the "if" clause is generally incorrect for the first conditional.',
  },
  {
    id: '3',
    text: "If I don't sleep well, I will be tired.",
    correct: "True",
    explanation: 'This sentence follows the first conditional structure. The "if" clause uses the present simple ("dont sleep well") and the result clause uses "will + base verb" ("will be tired"). It describes a likely outcome based on a given condition.',
  },
  {
    id: '4',
    text: "If it will be sunny, we will have a picnic.",
    correct: "False",
    explanation: 'This sentence does not align with the first conditional structure because it uses "will" in the "if" clause, suggesting a prediction or future intent rather than a condition. The first conditional structure should use the present simple in the "if" clause to indicate a condition that leads to a probable outcome. Using "will" in the "if" clause generally indicates a different type of structure, often implying future prediction or willingness, which does not fit the first conditionals format.',
  },
  {
    id: '5',
    text: "If I get up early, I will have time for breakfast.",
    correct: "True",
    explanation: 'This sentence follows the first conditional structure. The "if" clause uses the present simple tense ("get up early") and the result clause uses "will + base verb" ("will have"), indicating a likely outcome in the future based on a given condition. This is a typical example of a first conditional sentence.',
  },
  {
    id: '6',
    text: "If it snows, the roads are slippery.",
    correct: "False",
    explanation: 'This sentence does not follow the first conditional structure because it uses the present simple tense in both the "if" clause and the result clause. This structure is more characteristic of the zero conditional, which describes general truths or habitual outcomes. It indicates that the roads are always slippery when it snows, making it a zero conditional statement.',
  },
];

// Вопросы с двумя выпадающими списками для заполнения пробелов
const fillInTheBlankQuestions = [
  {
    id: '1',
    text: "If I ________________ out tonight, I ________________ to the cinema.",
    options1: ["go", "stay"],
    options2: ["will go", "won't go"],
    correct: ["go", "will go"],
    explanation: 'The "if" clause uses the present simple tense ("go"), indicating the condition. The result clause uses "will" + base verb ("will go"), suggesting the likely outcome based on the condition.',
  },
  {
    id: '2',
    text: "If you __________________ (get) back late, I __________________ (be) angry.",
    options1: ["get", "got"],
    options2: ["am", "will be"],
    correct: ["get", "will be"],
    explanation: 'The "if" clause uses the present simple ("get"), setting the condition. The result clause uses "will" + base verb ("will be"), indicating the likely response to the condition.',
  },
  {
    id: '3',
    text: "If we __________________ (not / see) each other tomorrow, we __________________ (see) each other next week.",
    options1: ["not see", "don't see"],
    options2: ["will see", "see"],
    correct: ["don't see", "will see"],
    explanation: 'The "if" clause uses the present simple in the negative ("dont see"), indicating the condition. The result clause uses "will" + base verb ("will see"), suggesting the probable outcome if the condition is met',
  },
];

const Lesson5 = () => {
    const [answers, setAnswers] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const handleChange = (questionId, answer) => {
      setAnswers((prev) => ({
        ...prev,
        [questionId]: answer,
      }));
    };
  
    const isCorrect = (questionId, expectedAnswer) => {
      if (Array.isArray(expectedAnswer)) {
        const userAnswers = answers[questionId] || [];
        return expectedAnswer.every((ans, i) => userAnswers[i] === ans);
      }
      return answers[questionId] === expectedAnswer;
    };
  
    const handleSubmit = () => {
      setIsSubmitted(true);
    };
  
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl mx-auto mt-8 mb-8"
      >
        <h1 className="text-3xl font-bold text-purple-600 text-center mb-8">Quiz Time!</h1>
  
        {/* Multiple Choice */}
        <h1 className="text-xl text-yellow-500 font-semibold">Multiple Selection Questions</h1>
        {multipleChoiceQuestions.map((question) => (
          <div key={question.id} className="mt-6">
            <p className="text-xl">
              {question.id}. {question.text}
            </p>
            {question.options.map((option, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 * index }}
                className="flex items-left gap-2 mt-2"
              >
                <input
                  type="radio"
                  name={`mc-${question.id}`}
                  value={option}
                  onChange={() => handleChange(question.id, option)}
                  className="cursor-pointer"
                />
                <span>{option}</span>
              </motion.div>
            ))}
            {isSubmitted && (
              <div
                className={`mt-2 p-4 ${
                  isCorrect(question.id, question.correct) ? 'bg-green-100' : 'bg-red-100'
                }`}
              >
                {isCorrect(question.id, question.correct) ? (
                  <>
                    <FaCheck className="text-green-600 inline-block mr-2" /> Correct!
                  </>
                ) : (
                  <>
                    <FaTimes className="text-red-600 inline-block mr-2" /> Incorrect. Correct answer:{" "}
                    {question.correct}.
                  </>
                )}
                <br />
                {question.explanation}
              </div>
            )}
          </div>
        ))}
  
        {/* True/False */}
        <br />
        <h1 className="text-xl text-yellow-500 font-semibold">True or False Questions</h1>
        {trueFalseQuestions.map((question) => (
          <div key={question.id} className="mt-6 flex items-center gap-4">
            <p className="text-xl">{question.id}. {question.text}</p>
            <select
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="border rounded-lg p-2 cursor-pointer"
            >
              <option value="">Select</option>
              <option value="True">True</option>
              <option value="False">False</option>
            </select>
            {isSubmitted && (
              <div
                className={`mt-2 p-4 ${
                  isCorrect(question.id, question.correct) ? 'bg-green-100' : 'bg-red-100'
                }`}
              >
                {isCorrect(question.id, question.correct) ? (
                  <>
                    <FaCheck className="text-green-600 inline-block mr-2" /> Correct!
                  </>
                ) : (
                  <>
                    <FaTimes className="text-red-600 inline-block mr-2" /> Incorrect. Correct answer:{" "}
                    {question.correct}.
                  </>
                )}
                <br />
                {question.explanation}
              </div>
            )}
          </div>
        ))}
  
        {/* Fill-in-the-Blanks */}
        <br />
        <h1 className="text-xl text-yellow-500 font-semibold">Double Blank Questions</h1>
        {fillInTheBlankQuestions.map((question) => (
          <div key={question.id} className="mt-6">
            <p className="text-xl">{question.text}</p>
            <div className="flex justify-center gap-4 mt-2">
              <select
                onChange={(e) => handleChange(question.id, [e.target.value, answers[question.id]?.[1]])}
                className="border rounded-lg p-2 cursor-pointer"
              >
                <option value="">Select</option>
                {question.options1.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                onChange={(e) => handleChange(question.id, [answers[question.id]?.[0], e.target.value])}
                className="border rounded-lg p-2 cursor-pointer"
              >
                <option value="">Select</option>
                {question.options2.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {isSubmitted && (
              <div
                className={`mt-2 p-4 ${
                  isCorrect(question.id, question.correct) ? 'bg-green-100' : 'bg-red-100'
                }`}
              >
                {isCorrect(question.id, question.correct) ? (
                  <>
                    <FaCheck className="text-green-600 inline-block mr-2" /> Correct!
                  </>
                ) : (
                  <>
                    <FaTimes className="text-red-600 inline-block mr-2" /> Incorrect. Correct answers:{" "}
                    {question.correct.join(", ")}.
                  </>
                )}
                <br />
                {question.explanation}
              </div>
            )}
          </div>
        ))}
  
        <div className="text-center mt-8">
          <button
            onClick={handleSubmit}
            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
          >
            Submit
          </button>
        </div>
      </motion.div>
    );
  };
  
  export default Lesson5;