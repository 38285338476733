import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


// Квиз

const testQuestions = [
  {
    id: 1,
    text: 'Every year The Guinness Book of World Records announces ... person in the world',
    options: ['more taller', 'the tallest', 'tallest','Tallestest'],
    correct: 'the tallest',
    explanation: 'This sentence uses the comparative form "tallest" to compare height between several people. The form "the tallest" means that the height of this person exceeds the height of everyone else'
  },
  {
    id: 2,
    text: 'This chair is ... than the other one.',
    options: ['much comfortable', 'many more comfortable', 'very comfortable','more comfortable'],
    correct: 'more comfortable',
    explanation: 'Here the comparative degree "more comfortable" is used to compare the level of comfort between two chairs. "More comfortable" indicates that this chair provides a higher level of comfort than another.'
  },
  {
    id: 3,
    text: 'Choose the correct comparative and superlative of the adjective "young"',
    options: ['younger', 'the youngest', 'the most young','more young'],
    correct: ['younger', 'the youngest'],
    explanation: 'For "young" the comparative is "younger" and the superlative is "the youngest"'
  },
  {
    id: 4,
    text: 'Choose the correct comparative and superlative of the adjective "comfortable".',
    options: ['more comfortable', 'comfortabler', 'the comfortablest','the most comfortable'],
    correct: ['more comfortable','the most comfortable'],
    explanation: 'For "comfortable" the comparative is "more comfortable" and the superlative is "the most comfortable"'
  },
  {
    id: 5,
    text: 'Write the correct superlative degree of the adjective "smart".',
    options: ['the smartest', 'smarter', 'the most smart', 'more smart'],
    correct: 'the smartest',
    explanation: 'The correct superlative form of "smart" is "the smartest".'
  },
  {
    id: 6,
    text: 'Write the correct comparative degree of the adjective "cheap".',
    options: ['cheaper', 'more cheap', 'the cheapest', 'the more cheap'],
    correct: 'cheaper',
    explanation: 'The correct comparative form of "cheap" is "cheaper".'
  }
];

// Задание на сопоставление
const initialAdjectives = [
  { id: 'adj-1', word: 'good', comparative: '....', correctAnswer: 'better', exp: 'When we compare two things to indicate that one is of higher quality or acceptable condition than the other, we use "better". For example, "This cake tastes better than that one."' },
  { id: 'adj-2', word: 'far', comparative: '....', correctAnswer: 'further', exp:'Far" means distance in space or time. When we compare distance or advancement in time, we use "further" to indicate a greater degree of distance.' },
  { id: 'adj-3', word: 'bad', comparative: '.....', correctAnswer: 'worse' , exp: '"Bad" means poor quality or unfortunate condition. When we compare two things and indicate that one is of lower quality or more unacceptable condition than the other, we use "worse".'},
  { id: 'adj-4', word: 'little', comparative: '....', correctAnswer: 'less', exp: '"Little" usually means a small amount or insignificant value. However, when we talk about quantity or degree, and indicate that one quantity is less than another, we use "less".' },
  { id: 'adj-5', word: 'old', comparative: '.....', correctAnswer: 'older', exp:'"Old" means age or time of existence. When we compare the ages of two things or people and indicate that one is older than the other, we use "older". ' },
];

const initialComparatives = [
  { id: 'comp-1', word: 'worse' },
  { id: 'comp-2', word: 'better' },
  { id: 'comp-3', word: 'further' },
  { id: 'comp-4', word: 'older' },
  { id: 'comp-5', word: 'less' },
];

// Компонент с квизом
function Quiz({onNextTask}) {
    const [testAnswers, setTestAnswers] = useState({});
    const [testSubmitted, setTestSubmitted] = useState(false);
  
    const handleTestSelect = (questionId, option) => {
      setTestAnswers((prev) => {
        const currentAnswer = prev[questionId] || [];
        if (currentAnswer.includes(option)) {
          return {
            ...prev,
            [questionId]: currentAnswer.filter((ans) => ans !== option),
          };
        } else {
          return {
            ...prev,
            [questionId]: [...currentAnswer, option],
          };
        }
      });
    };
  
    const handleTestSubmit = () => {
      setTestSubmitted(true);
    };
  
    const isAnswerCorrect = (question) => {
      const userAnswer = testAnswers[question.id] || [];
      const correctAnswers = Array.isArray(question.correct) ? question.correct : [question.correct];
      return correctAnswers.sort().toString() === userAnswer.sort().toString();
    };
  
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="mt-8 mb-8 flex flex-col justify-center items-center"
      >
        <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Quiz</h1>
          {testQuestions.map((question, qIndex) => (
            <div key={question.id} className="mb-6">
              <p className="text-lg font-semibold text-gray-800 mb-2">{question.text}</p>
              <div className="grid gap-4">
                {question.options.map((option, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 * index }}
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={() => handleTestSelect(question.id, option)}
                  >
                    <input
                      type="checkbox"
                      id={`option-${qIndex}-${index}`}
                      name={`question-${question.id}`}
                      value={option}
                      checked={(testAnswers[question.id] || []).includes(option)}
                      className="mr-2 cursor-pointer"
                    />
                    <label htmlFor={`option-${qIndex}-${index}`} className="cursor-pointer">{option}</label>
                  </motion.div>
                ))}
              </div>
            </div>
          ))}
  
          <div className="text-center mt-8">
            <button
              onClick={handleTestSubmit}
              className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
            >
              Submit
            </button>
          </div>
  
          {testSubmitted && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-8 space-y-6"
            >
              {testQuestions.map((question) => (
                <div
                  key={question.id}
                  className={`flex flex-col p-4 rounded-lg ${
                    isAnswerCorrect(question) ? 'bg-green-200' : 'bg-pink-200'
                  }`}
                >
                  <p className="text-lg font-semibold text-gray-800">{question.text}</p>
                  {(testAnswers[question.id] || []).map((answer) => (
                    <p key={answer} className={`text-sm ${
                      (Array.isArray(question.correct) ? question.correct : [question.correct]).includes(answer) ? 'text-green-600' : 'text-red-600'
                    }`}>
                      {answer}
                    </p>
                  ))}
  
                  {isAnswerCorrect(question) ? (
                    <p className="text-green-600 flex items-center text-sm">
                      <FaCheck className="mr-2" /> Correct! {question.explanation}
                    </p>
                  ) : (
                    <div className="flex items-center text-red-600 text-sm">
                      <FaTimes className="mr-2" /> Incorrect! The correct answer is {Array.isArray(question.correct) ? question.correct.join(', ') : question.correct}.{question.explanation}
                    </div>
                  )}
                </div>
              ))}
            </motion.div>
          )}
  
          <div className="text-center mt-8">
            <button
              onClick={onNextTask}
              className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-700 transition"
            >
              Next Task
            </button>
          </div>
        </div>
      </motion.div>
    );
  };
  



  function Match() {
    const [adjectives, setAdjectives] = useState(
      initialAdjectives.map((adj) => ({ ...adj, comparativeId: null }))
    );
    const [comparatives, setComparatives] = useState(initialComparatives);
    const [matchSubmitted, setMatchSubmitted] = useState(false);
  
    const onDragEnd = (result) => {
      const { source, destination } = result;
  
      if (!destination || source.droppableId === destination.droppableId) {
        return;
      }
  
      let newAdjectives = [...adjectives];
      let newComparatives = [...comparatives];
  
      if (source.droppableId === 'comparative-list' && destination.droppableId.startsWith('adj')) {
        const adjIndex = newAdjectives.findIndex(adj => adj.id === destination.droppableId);
  
        if (adjIndex >= 0) {
          if (newAdjectives[adjIndex].comparativeId !== null) {
            const existingCompIndex = newComparatives.findIndex(
              comp => comp.id === newAdjectives[adjIndex].comparativeId
            );
            if (existingCompIndex === -1) {
              newComparatives.push({
                id: newAdjectives[adjIndex].comparativeId,
                word: newAdjectives[adjIndex].comparative,
              });
            }
          }
  
          const [removedComp] = newComparatives.splice(source.index, 1);
  
          newAdjectives[adjIndex].comparative = removedComp.word;
          newAdjectives[adjIndex].comparativeId = removedComp.id;
        }
      }
  
      setComparatives(newComparatives);
      setAdjectives(newAdjectives);
    };
  
    const handleMatchSubmit = () => {
      setMatchSubmitted(true);
    };
  
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col justify-center items-center my-8 p-6"
      >
        <div className="w-full max-w-4xl bg-white p-8 rounded-xl shadow-xl">
          <h1 className="text-3xl font-bold text-center text-purple-500 mb-8">
            Match the Adjectives with Their Comparatives!
          </h1>
  
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex flex-col justify-center item-center gap-6">
              {adjectives.map((adj) => (
                <motion.div
                  key={adj.id}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className="flex justify-center items-center gap-4"
                >
                  <span className="text-2xl text-teal-800">{adj.word}</span>
  
                  <Droppable droppableId={adj.id}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="bg-yellow-200 border-2 border-teal-600 rounded-lg h-10 w-40 flex items-center justify-center"
                      >
                        {adj.comparative ? (
                          <span className="text-teal-800">{adj.comparative}</span>
                        ) : (
                          <span className="text-gray-400">Drop here!</span>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </motion.div>
              ))}
  
              <Droppable droppableId="comparative-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className="flex justify-center gap-6">
                    {comparatives.map((comp, index) => (
                      <Draggable key={comp.id} draggableId={comp.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-purple-300 text-purple-800 px-4 py-2 rounded-lg"
                          >
                            {comp.word}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
  
          <div className="text-center mt-8">
            <motion.button
              onClick={handleMatchSubmit}
              className="px-6 py-3 bg-purple-600 text-white rounded-xl hover:bg-purple-700 transition"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Submit
            </motion.button>
          </div>
  
          {matchSubmitted && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-8 space-y-4"
            >
              {adjectives.map((adj) => (
                <div 
                  key={adj.id} 
                  className={`flex flex-col p-4 rounded-lg ${
                    adj.comparative === adj.correctAnswer ? 'bg-green-200' : 'bg-pink-200'
                  }`}
                >
                  <p className="text-xl text-teal-800">
                    {adj.word} - {adj.comparative ? adj.comparative : '...'}
                  </p>
            
                  {adj.comparative === adj.correctAnswer ? (
                    <p className="text-green-600 flex items-center text-sm">
                      <FaCheck className="mr-2" /> Correct! Great job! {adj.exp}
                    </p>
                  ) : (
                    <p className="text-red-600 flex items-center text-sm">
                      <FaTimes className="mr-2" /> Not quite! Try again! The correct answer: {adj.correctAnswer}. {adj.exp}
                    </p>
                  )}
                </div>
              ))}
            </motion.div>
          )}
        </div>
      </motion.div>
    );
  }
  
function Lesson8() {
    const [currentTask, setCurrentTask] = useState('quiz');

    const handleNextTask = () => {
        setCurrentTask('match');
    };

    return currentTask === 'quiz' ? (
        <Quiz onNextTask={handleNextTask} />
    ) : (
        <Match />
    );
}

export default Lesson8;

