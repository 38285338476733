import React from "react";
import { Link } from "react-router-dom";

function Lessons(){
    return(
        <div>
            <section className="relative py-12"> 
                <div className="container mx-auto px-6"> 
                    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {[
                        { title: 'GOING TO', image: './assets/goingto.png' ,unit:'Module 3: Earth and our place on it', icon: '1' , desc: '"Going to" is a structure in English used to indicate future intentions, plans, or predictions. It is formed by conjugating the verb "to be" (am, is, are) followed by the infinitive form of the main verb.'},
                        { title: 'SO AND SUCH',image: './assets/sosuch.png',unit: 'Module 2: Exercise and sport', icon: '2', desc: "So and such are both words in English that are used to add emphasis or provide additional information about something. However, they are used in slightly different ways" },
                        { title: 'PRESENT PERFECT VS PAST SIMPLE',unit: 'Module 1: Hobbies and qualities',image: './assets/present.png', icon: '3' , desc: "This concise task prompts learners to quickly select the appropriate tense for each sentence, reinforcing their understanding of the present perfect and past simple tenses."},
                        { title: 'ZERO CONDITIONAL',image: './assets/zero.png', unit:'Module 9: Science and technology',icon: '4' ,desc: 'In English grammar, a zero conditional is used to express general truths, facts, or things that are always true under certain conditions.'},
                        { title: 'FIRST CONDITIONAL',image: './assets/firstcond.png',unit:'Module 9: Science and technology', icon: '5', desc:'The first conditional is used to talk about things which are possible in the present or the future — things which may happen.' },
                        { title: 'SECOND CONDITIONAL',image: './assets/second.png', unit:'Module 9: Science and technology',icon: '6', desc:"The second conditional is used to talk about things which are unreal (not true or not possible) in the present or the future -- things which don't or won't happen." },
                        { title: 'PASSIVE VOICE', image: './assets/passive.png',unit:'Module 5: Reading for pleasure', icon: '7' , desc: "One of the two “voices” of verbs ( see also active voice ). A verb is in the passive voice when the subject of the sentence is acted on by the verb."},
                        { title: 'COMPARATIVES AND SUPERLATIVES',image: './assets/compsuper.png', unit:'Module 6: Traditions and language',icon: '8',desc:'A comparative form or comparative structure expresses a difference between two people, things or animals. A superlative form or structure expresses the highest degree of a quality of a person, thing or animal' },
                        { title: 'PAST SIMPLE',image:'./assets/pastsimple.png' ,unit:'Module 8: Travel and tourism',icon: '9' , desc:"One of the modalities of the grammatical category of tense, a form of a finite verb used to describe situations that takes place before the moment of speech or before the moment described in speech."},
                        { title: 'PRESENT CONTINUOUS', image:'./assets/presentcont.png',unit:'Module 8: Travel and tourism',icon: '10' , desc:'The present continuous verb tense indicates that an action or condition is happening now, frequently, and may continue into the future.'},
                    ].map((feature, index) => (
                        <div
                        key={index}
                        className="flex flex-col justify-between rounded-lg bg-white p-6 shadow-lg transition-all hover:shadow-2xl"
                        >
                        <div>
                                <img
                                    src={feature.image}
                                    alt={feature.title}
                                    className="w-full h-32 object-cover rounded-lg mb-4"
                                />
                                <h3 className="text-yellow-500 font-bold">{feature.title}</h3>
                                <h4 className="text-purple-500 font-bold">({feature.unit})</h4>
                                <p className="text-gray-600">{feature.desc}</p>
                            </div>
                            <div className="mt-4">
                                <Link to={`/lesson${feature.icon}`} className="text-purple-500 font-bold hover:underline">
                                    START
                                </Link>
                            </div>
                            </div>
                    ))}
                    </div>
                </div>
                </section>
        </div>
    );
}

export default Lessons;