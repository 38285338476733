import React, { useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";

const questions = [
    {
        "id": 1,
        "text": "I think it ___(rain) tomorrow.",
        "options": ["will rain", "is going to rain"],
        "correct": "will rain",
        "explanation": "Use 'will' for predictions based on opinion or belief about the future."
    },
    {
        "id": 2,
        "text": "Look at those clouds! It ___(rain) soon.",
        "options": ["will rain", "is going to rain"],
        "correct": "is going to rain",
        "explanation": "Use 'going to' for predictions based on present evidence."
    },
    {
        "id": 3,
        "text": "She ___(meet) her friends this evening.",
        "options": ["will meet", "is going to meet"],
        "correct": "is going to meet",
        "explanation": "Use 'going to' for plans or intentions."
    },
    {
        "id": 4,
        "text": "We ___(move) to a new house next month.",
        "options": ["will move", "are going to move"],
        "correct": "are going to move",
        "explanation": "Use 'going to' for planned actions in the future."
    },
    {
        "id": 5,
        "text": "They ___(not/finish) the project on time.",
        "options": ["will not finish", "are not going to finish"],
        "correct": "will not finish",
        "explanation": "Use 'won't' for negative predictions about the future."
    },
    {
        "id": 6,
        "text": "What ___(you/do) this weekend?",
        "options": ["will you do", "are you going to do"],
        "correct": "are you going to do",
        "explanation": "Use 'going to' to ask about someone's plans."
    },
    {
        "id": 7,
        "text": "I ___(help) you with your homework if you want.",
        "options": ["will help", "am going to help"],
        "correct": "will help",
        "explanation": "Use 'will' for offers or decisions made at the moment of speaking."
    },
    {
        "id": 8,
        "text": "He ___(buy) a new car soon.",
        "options": ["will buy", "is going to buy"],
        "correct": "is going to buy",
        "explanation": "Use 'going to' for planned actions in the future."
    },
    {
        "id": 9,
        "text": "The train ___(leave) at 6 PM.",
        "options": ["will leave", "is going to leave"],
        "correct": "will leave",
        "explanation": "Use the present simple for scheduled events."
    },
    {
        "id": 10,
        "text": "She ___(become) a doctor one day.",
        "options": ["will become", "is going to become"],
        "correct": "will become",
        "explanation": "Use 'will' for predictions based on opinion or belief about the future."
    }    
];

function Lesson1() {
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleSelect = (questionId, option) => {
        setSelectedAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: option,
        }));
    };

    const handleSubmit = () => {
        setSubmitted(true);
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center mb-8 mt-8">
            <div className="w-full max-w-5xl bg-white p-8 rounded-xl shadow-xl">
                <h1 className="text-3xl font-bold text-center text-purple-800 mb-8">Complete the Sentences</h1>
                <div className="space-y-6">
                    {questions.map((question) => (
                        <motion.div
                            key={question.id}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="bg-purple-100 p-6 rounded-lg shadow-lg"
                        >
                            <p className="text-xl text-gray-800">
                                {question.text.replace(
                                    '___',
                                    `(${selectedAnswers[question.id] || '___'})`
                                )}
                            </p>
                            <div className="flex justify-center gap-4 mt-4">
                                {question.options.map((option) => (
                                    <button
                                        key={option}
                                        onClick={() => handleSelect(question.id, option)}
                                        className={`px-4 py-2 rounded-lg transition-all border ${
                                            selectedAnswers[question.id] === option
                                                ? 'bg-purple-300 text-purple-800 border-purple-600'
                                                : 'bg-white text-gray-700 hover:bg-gray-200 border-gray-300'
                                        }`}
                                    >
                                        {option}
                                    </button>
                                ))}
                            </div>
                            {submitted && (
                                <div className="mt-4">
                                    {selectedAnswers[question.id] === question.correct ? (
                                        <p className="text-lg text-green-600 flex justify-center items-center">
                                            <FaCheck className="mr-2" /> Correct!
                                        </p>
                                    ) : (
                                        <p className="text-lg text-red-600 flex justify-center items-center">
                                            <FaTimes className="mr-2" /> Incorrect!
                                        </p>
                                    )}
                                    {selectedAnswers[question.id] !== question.correct && (
                                        <p className="text-purple-600 mt-2">{question.explanation}</p>
                                    )}
                                </div>
                            )}
                        </motion.div>
                    ))}
                </div>
                {!submitted && (
                    <div className="text-center mt-8">
                        <button
                            onClick={handleSubmit}
                            className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
                        >
                            Submit Answers
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Lesson1;